import React, { useState, useEffect } from "react";
import Wrapper from "../common/Wrapper";
import DivFooter from "../common/DivFooter";
import Header from "../common/Header";
import Empty from "../common/Empty";
import styled from "styled-components";
import axios from "axios"; // Import axios for making API requests

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    .selectBox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100vw;
    }
    .memo-box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-name {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
    }
    select {
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    display: flex;
    flex-direction: row;
    table {
      margin: 0 auto;
    }
    table,
    td,
    tr,
    th {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
    }
    td {
      width: 10%;
      height: 60px;
      font-size: 12px;
    }
    .box3 {
      width: 100%;
      height: 60px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .box1,
    .box2 {
      width: 10%;
      height: 30px;
    }
    .has-data {
      background-color: orange; /* 데이터가 있는 경우 배경색 */
    }
    .no-data {
      background-color: aliceblue;
    }
    .memo {
      width: 90vw;
      margin-top: 20px;
      margin-left: 50px;
      padding: 10px;
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      position: absolute;
    }
    .memo-item {
      list-style: none;
      cursor: pointer;
      padding: 5px;
      margin-bottom: 5px;
      border: 1px solid #ccc;
      background-color: #fff;
    }
    button {
      font-size: 14px;
    }
    h2 {
      font-size: 15px;
      text-align: center;
      margin: 5px 0;
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
     {
      margin-left: 50px;
    }

    input {
      font-size: 20px;
    }
    textarea {
      font-size: 20px;
    }
    select {
      font-size: 20px;
    }
    display: flex;
    flex-direction: row;
    table {
      margin: 0 auto;
    }
    table,
    td,
    tr,
    th {
      border: 3px solid black;
      border-collapse: collapse;
      text-align: center;
    }
    td {
      width: 100px;
      height: 60px;
    }
    .box3 {
      width: 100px;
      height: 60px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .box1,
    .box2 {
      width: 100px;
      height: 30px;
    }
    .has-data {
      background-color: orange; /* 데이터가 있는 경우 배경색 */
    }
    .no-data {
      background-color: aliceblue;
    }
    .memo {
      width: 300px;
      margin-top: 20px;
      margin-left: 50px;

      padding: 10px;
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      position: absolute;
    }
    .memo-item {
      list-style: none;
      cursor: pointer;
      padding: 5px;
      margin-bottom: 5px;
      border: 1px solid #ccc;
      background-color: #fff;
    }
    button {
      font-size: 20px;
    }
  }
`;

const LessonTimePage = () => {
  const [lessons, setLessons] = useState({});
  const [lessonType, setLessonType] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [name, setName] = useState("");
  const [memos, setMemos] = useState([]); // State for memos

  // Fetch lessons and memos on component mount
  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const res = await axios.get("http://43.201.52.51:5001/api/lessons");
        const lessonsData = res.data.reduce((acc, lesson) => {
          acc[`${lesson.day}-${lesson.time}`] = {
            name: lesson.name,
            type: lesson.type,
          };
          return acc;
        }, {});
        setLessons(lessonsData);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchMemos = async () => {
      try {
        const res = await axios.get("http://43.201.52.51:5001/api/memos");
        setMemos(
          res.data.map((memo) => ({ id: memo.id, content: memo.content }))
        );
      } catch (err) {
        console.error(err);
      }
    };

    fetchLessons();
    fetchMemos();
  }, []);

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLessonTypeChange = (e) => {
    setLessonType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedDay && selectedTime && name && lessonType) {
      const newLesson = {
        day: selectedDay,
        time: selectedTime,
        name,
        type: lessonType,
      };
      try {
        await axios.post("http://43.201.52.51:5001/api/lessons", newLesson);
        setLessons((prevLessons) => ({
          ...prevLessons,
          [`${selectedDay}-${selectedTime}`]: newLesson,
        }));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleMemoSubmit = async (e) => {
    e.preventDefault();
    const memoContent = e.target.memoContent.value;
    try {
      const res = await axios.post("http://43.201.52.51:5001/api/memos", {
        content: memoContent,
      });
      const newMemo = { id: res.data.id, content: memoContent };
      setMemos((prevMemos) => [...prevMemos, newMemo]);
      e.target.reset();
    } catch (err) {
      console.error(err);
    }
  };

  const handleMemoDelete = async (index) => {
    try {
      const memoId = memos[index].id;
      await axios.delete(`http://43.201.52.51:5001/api/memos/${memoId}`);
      setMemos((prevMemos) => prevMemos.filter((_, i) => i !== index));
    } catch (err) {
      console.error(err);
    }
  };

  const handleDataDoubleClick = async (day, time) => {
    const check = confirm("정말 예약을 삭제하시겠습니까?");
    if (!check) {
      return;
    } else {
      const key = `${day}-${time}`;
      const updatedLessons = { ...lessons };
      if (updatedLessons[key]) {
        try {
          await axios.delete("http://43.201.52.51:5001/api/lessons", {
            data: { day, time },
          });
          delete updatedLessons[key];
          setLessons(updatedLessons);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  const renderCell = (day, time) => {
    const key = `${day}-${time}`;
    const lesson = lessons[key];

    // 데이터가 있는 경우 클래스 추가
    const tdClass = lesson ? "has-data" : "no-data";

    return (
      <td
        key={key}
        className={tdClass}
        onDoubleClick={() => handleDataDoubleClick(day, time)}
      >
        {lesson?.type === "1시간" && (
          <div
            className="box3 has-data"
            onDoubleClick={() => handleDataDoubleClick(day, time)}
          >
            {lesson.name}
          </div>
        )}
        {lesson?.type === undefined && (
          <div
            className="box3 no-data"
            onDoubleClick={() => handleDataDoubleClick(day, time)}
          ></div>
        )}
        {lesson?.type === "1부" && (
          <div onDoubleClick={() => handleDataDoubleClick(day, time)}>
            <div className="box1 has-data">{lesson.name}</div>
            <div className="box2 no-data"></div>
          </div>
        )}
        {lesson?.type === "2부" && (
          <div onDoubleClick={() => handleDataDoubleClick(day, time)}>
            <div className="box1 no-data"></div>
            <div className="box2 has-data">{lesson.name}</div>
          </div>
        )}
      </td>
    );
  };

  const times = [
    "오전 9시",
    "오전 10시",
    "오전 11시",
    "오후 12시",
    "오후 1시",
    "오후 2시",
    "오후 3시",
    "오후 4시",
    "오후 5시",
    "오후 6시",
    "오후 7시",
    "오후 8시",
    "오후 9시",
  ];

  const days = ["월", "화", "수", "목", "금", "토", "일"];

  return (
    <Div>
      <Wrapper>
        <DivFooter>
          <Header />
          <Empty />
          <div>
            <form onSubmit={handleSubmit}>
              <div className="selectBox">
                <select value={selectedDay} onChange={handleDayChange}>
                  <option value="">요일 선택</option>
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <select value={selectedTime} onChange={handleTimeChange}>
                  <option value="">시간 선택</option>
                  {times.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <select value={lessonType} onChange={handleLessonTypeChange}>
                  <option value="">수업 시간</option>
                  <option value="1시간">1시간</option>
                  <option value="1부">1부</option>
                  <option value="2부">2부</option>
                </select>
              </div>
              <div className="input-name">
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="이름 입력"
                />
                <button type="submit">레슨 등록</button>
              </div>
            </form>

            {/* 특이사항 입력 폼 */}
            <form onSubmit={handleMemoSubmit}>
              <h2>특이사항</h2>
              <div className="memo-box">
                <textarea
                  name="memoContent"
                  placeholder="메모하세요"
                ></textarea>
                <button type="submit">메모하기</button>
              </div>
            </form>

            {/* 메모 리스트 표시 */}
            {memos.length > 0 && (
              <div className="memo">
                <h3>메모:</h3>
                <ul>
                  {memos.map((memo, index) => (
                    <li
                      key={index}
                      className="memo-item"
                      onDoubleClick={() => handleMemoDelete(index)}
                    >
                      {memo.content}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <table>
              <thead>
                <tr>
                  <td>시간</td>
                  {days.map((day) => (
                    <td key={day}>{day}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {times.map((time) => (
                  <tr key={time}>
                    <td>{time}</td>
                    {days.map((day) => renderCell(day, time))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Empty />
        </DivFooter>
      </Wrapper>
    </Div>
  );
};

export default LessonTimePage;
